<template>
  <layout-base>
    <loading-progress :loading="loading"></loading-progress>
    <MobileSpiner :loading="loading" />
    <PaymentsTable class="mt-1"></PaymentsTable>
  </layout-base>
</template>

<script>
import PaymentsTable from "../modules/payments";
export default {
  components: { PaymentsTable },
  data() {
    return {
      loading: true,
    };
  },
  mounted() {
    setTimeout(() => {
      this.loading = false;
    }, 500);
  },
  created() {
    document.title = "Платежи | " + window.appBaseTitle;
  },
};
</script>

<style>
</style>