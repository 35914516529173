import api from "@/api/index";
import { days } from "@/plugins/dayjs/index";
import { setEmoji } from "@/handlers/emoji";

export function getPaymentsList(that) {
  let url = "payment_v1/list";
  that.loading = true;
  that.paymentsList = [];
  api
    .get(url, {
      params: {
        dateStart:
          that.filters.date.from && that.filters.date.to
            ? days(that.filters.date.from, "server-start")
            : null,
        dateEnd:
          that.filters.date.from && that.filters.date.to
            ? days(that.filters.date.to, "server-end")
            : null,
        rows: that.rows,
        page: that.page,
        company: that.companys,
        sort: that.sort,
        desc: that.desc,
        description: that.filters.description
          ? that.filters.description.trim()
          : null,
        contract_num: that.filters.contract_num
          ? that.filters.contract_num.trim()
          : null,
        inn: that.filters.inn ? that.filters.inn.trim() : null,
        sum_from: that.filters.sum.from ? that.filters.sum.from : null,
        sum_to: that.filters.sum.to ? that.filters.sum.to : null,
      },
    })
    .then((response) => {
      console.log(response);
      let array = response.data.result;
      let total = response.data.total
      if (array.length != 0) {
        that.totalRecords = total["count"];
        that.paymentsList = [...array, ...[total]];
      } else {
        that.paymentsList = null;
      }
      console.info(
        `${response.statusText} ${response.status} ${setEmoji("access")}`
      );
    })
    .catch((err) => {
      console.log(err);
      console.log(err.request);
      let statusCode = err.request.status;
      let statusText = err.request.statusText;
      console.error(setEmoji("error") + statusCode + " " + statusText);
      switch (statusCode) {
        case 0:
          that.$toast.add({
            severity: "warn",
            summary: `${setEmoji("error")} Нет связи с сервером`,
            detail: "Попробуйте позднее!",
            life: 5000,
          });
          break;
        case 403:
          that.$toast.add({
            severity: "warn",
            summary: `${setEmoji("lock")} Время сессии истекло`,
            detail: "Требуется авторизация",
            life: 5000,
          });
          that.$router.push("/login");
          break;
        case 404:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Ошибка ${statusCode}`,
            detail: "Запрос не найден",
            life: 5000,
          });
          break;
        case 406:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Ошибка ${statusCode}`,
            detail: "Укажите компании",
            life: 3000,
          });
          break;
        default:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Неизвестная ошибка ${statusCode}`,
            detail: "Попробуйте позднее!",
            life: 5000,
          });
          break;
      }
    })
    .finally(() => {
      api
        .get(url, {
          params: {
            group: "total",
            dateStart:
              that.filters.date.from && that.filters.date.to
                ? days(that.filters.date.from, "server-start")
                : null,
            dateEnd:
              that.filters.date.from && that.filters.date.to
                ? days(that.filters.date.to, "server-end")
                : null,
            rows: that.rows,
            page: that.page,
            company: that.companys,
            sort: that.sort,
            desc: that.desc,
            description: that.filters.description
              ? that.filters.description.trim()
              : null,
            contract_num: that.filters.contract_num
              ? that.filters.contract_num.trim()
              : null,
            inn: that.filters.inn ? that.filters.inn.trim() : null,
            sum_from: that.filters.sum.from ? that.filters.sum.from : null,
            sum_to: that.filters.sum.to ? that.filters.sum.to : null,
          },
        })
        .then((response) => {
          that.total = [];
          that.total.push(response.data);
          if (that.paymentsList !== null) {
            if (that.paymentsList.length != 0) {
              if (
                that.paymentsList[that.paymentsList.length - 1].sum !=
                  that.total[0].sum ||
                that.paymentsList.length === 1
              ) {
                that.paymentsList = [...that.paymentsList, ...that.total];
              }
            }
          }

          console.info(
            `${response.statusText} ${response.status} ${setEmoji("access")}`
          );
        })
        .catch((err) => {
          console.log(err);
          console.log(err.request);
          let statusCode = err.request.status;
          let statusText = err.request.statusText;
          console.error(setEmoji("error") + statusCode + " " + statusText);
          switch (statusCode) {
            case 0:
              that.$toast.add({
                severity: "warn",
                summary: `${setEmoji("error")} Нет связи с сервером`,
                detail: "Попробуйте позднее!",
                life: 5000,
              });
              break;
            case 403:
              that.$toast.add({
                severity: "warn",
                summary: `${setEmoji("lock")} Время сессии истекло`,
                detail: "Требуется авторизация",
                life: 5000,
              });
              that.$router.push("/login");
              break;
            case 404:
              that.$toast.add({
                severity: "error",
                summary: `${setEmoji("error")} Ошибка ${statusCode}`,
                detail: "Запрос не найден",
                life: 5000,
              });
              break;
            case 406:
              that.$toast.add({
                severity: "error",
                summary: `${setEmoji("error")} Ошибка ${statusCode}`,
                detail: "Укажите компании",
                life: 3000,
              });
              break;
            default:
              that.$toast.add({
                severity: "error",
                summary: `${setEmoji(
                  "error"
                )} Неизвестная ошибка ${statusCode}`,
                detail: "Попробуйте позднее!",
                life: 5000,
              });
              break;
          }
        });
      setTimeout(() => {
        that.loading = false;
      }, 2000);
    });
}
