<template>
  <div
    v-if="curCompany"
    class="mt-1 flex flex-column justify-content-between align-items-stretch h-full"
    :class="{ ' overlock': loading }"
  >
    <div class="payments-wrapper">
      <div class="table-header p-datatable-header py-0 px-0 bold">
        <div class="grid justify-content-end m-0 align-items-center">
          <SelectCompany
            class="col-10 md:col-6 m-0 p-1"
            v-on:upToParentItems="updateSelectedCompany"
            :items="companyListLite"
            :selected-items="companys"
            :show-toggle-all="true"
          />
          <div>
            <Button
              :disabled="resetDisables"
              @click="resetFiltres"
              v-tooltip.left="'Сбросить все фильтры'"
              iconPos="right"
              icon="pi pi-filter-slash"
              class="p-button-sm col-2 sm:mx-2 mx-0"
            />
          </div>
        </div>
      </div>
      <DataTable
        v-if="sort"
        :rows="rows"
        :loading="loading"
        :paginator="false"
        responsiveLayout="scroll"
        :scrollable="true"
        stripedRows
        showGridlines
        :lazy="true"
        filterDisplay="menu"
        class="p-datatable-sm mt-1"
        :value="paymentsList"
        sortMode="single"
        @sort="onSort($event)"
        :rowClass="rowClass"
      >
        <template #empty>
          <div class="text-center w-full text-sm">
            Данные по платежам не найдены. Попробуйте изменить условия запроса.
          </div>
        </template>
        <template #loading>
          <b style="color: #fff"
            >Загрузка данных о транзакциях. Пожалуйста подождите.</b
          >
        </template>
        <Column field="date" :showFilterMenu="false" :sortable="sort">
          <template #header>
            <TheFilterDate
              :type="input"
              :dsc="false"
              v-model:valueFrom="filters.date.from"
              v-model:valueTo="filters.date.to"
            ></TheFilterDate>
            <span style="order: 3">Дата</span>
          </template>
          <template #body="slotProps">
            <div :class="{ 'font-bold': !slotProps.data.date }">
              {{
                slotProps.data.date ? formatTime(slotProps.data.date) : "Итог:"
              }}
            </div>
          </template>
        </Column>
        <Column field="company" :sortable="sort">
          <template #header>
            <span style="order: 3">Компания</span>
          </template>
          <template #body="slotProps">
            {{
              slotProps.data.company
                ? companyList.filter(
                    (el) => el.id == slotProps.data.company
                  )[0]["name"]
                : ""
            }}
          </template></Column
        >
        <Column
          field="description"
          :showFilterMenu="false"
          style="width: 150px; max-width: 150px"
          :sortable="sort"
        >
          <template #header>
            <FilterSearch
              :type="input"
              :dsc="false"
              v-model="filters.description"
            ></FilterSearch>
            <span style="order: 3">Описание</span>
          </template>
          <template #body="slotProps">
            {{ slotProps.data.description }}
          </template>
        </Column>
        <Column field="sum" :sortable="sort">
          <template #header>
            <FilterFromTo
              v-model:valueFrom="filters.sum.from"
              v-model:valueTo="filters.sum.to"
            ></FilterFromTo>

            <span style="order: 3">Сумма</span>
          </template>
          <template #body="slotProps">
            {{
              slotProps.data.sum
                ? slotProps.data.sum
                : slotProps.data.payment_sum
            }}
            Р
          </template></Column
        >
        <Column field="contract_num" :sortable="sort">
          <template #header>
            <FilterSearch
              :type="input"
              :dsc="false"
              v-model="filters.contract_num"
            ></FilterSearch>

            <span style="order: 3">Номер договора</span>
          </template>
          <template #body="slotProps">
            {{ slotProps.data.contract_num ? slotProps.data.contract_num : "" }}
          </template></Column
        >
        <Column field="inn" :sortable="sort">
          <template #header>
            <FilterSearch
              :type="input"
              :dsc="false"
              v-model="filters.inn"
            ></FilterSearch>

            <span style="order: 3"> ИНН</span>
          </template>
          <template #body="slotProps">
            {{ slotProps.data.inn ? slotProps.data.inn : "" }}
          </template></Column
        >
      </DataTable>
      <Paginator
        class="payments-paginator"
        :rows="rows"
        :rowsPerPageOptions="[100, 300, 500, 1000]"
        @page="onPage($event)"
        :totalRecords="totalRecords"
      ></Paginator>
    </div>
  </div>
</template>

<script>
import SelectCompany from "../../../components/SelectCompany";
import store from "@/store";
import FilterSearch from "@/components/Transactions/Filtres/FilterSearch";
import FilterFromTo from "@/components/Transactions/Filtres/FilterFromTo";

import { getPaymentsList } from "../api/payments";
import { timeFormater } from "@/handlers/timeFormater";
import TheFilterDate from "../../../components/TheFilterDate.vue";
export default {
  components: { SelectCompany, FilterSearch, FilterFromTo, TheFilterDate },
  name: "PaymentsTables",
  data() {
    return {
      paymentsList: null,
      rows: 100,
      page: 1,
      totalRecords: 0,
      companys: [],
      loading: false,
      desc: true,
      sort: "",
      total: [],
      filters: {
        description: null,
        contract_num: null,
        inn: null,
        sum: {
          from: null,
          to: null,
        },
        date: {
          from: null,
          to: null,
        },
      },
      sort: true,
    };
  },
  methods: {
    rowClass(data) {
      return !data.date ? "pay-result-row" : " ";
    },
    onPage(event) {
      this.page = event.page + 1;
      this.rows = event.rows;
      getPaymentsList(this);
    },
    onSort(event) {
      this.sort = event.sortField;
      event.sortOrder == 1 ? (this.desc = true) : (this.desc = null);
      getPaymentsList(this);
    },
    formatTime(time) {
      return timeFormater(time);
    },
    updateSelectedCompany(data) {
      this.companys = data.items;
      getPaymentsList(this);
    },
    iterateObject(obj) {
      for (let key in obj) {
        let value = obj[key];
        if (typeof value == "object") {
          // работаем рекурсивно
          this.iterateObject(value);
        } else {
          obj[key] = null;
        }
      }
    },
    resetFiltres() {
      this.iterateObject(this.filters);
      this.sort = false;
      setTimeout(() => {
        this.sort = true;
      }, 300);
      getPaymentsList(this);
    },
  },

  computed: {
    curCompany() {
      return store.state.company.curCompany;
    },
    companyList() {
      return store.state.company.companyList;
    },
    companyListLite() {
      return store.state.company.companyList.map(function (el) {
        return {
          id: el.id,
          name: el.name,
        };
      });
    },
  },
  watch: {
    curCompany() {
      this.companys = [this.curCompany.id];
      getPaymentsList(this);
    },
    companys(value) {
      //   console.log(value.length);
      if (value.length === 0) {
        this.companys = [this.curCompany.id];
      }
      //   this.companys = [this.curCompany.id];
    },
    filters: {
      deep: true,
      handler(data) {
        if (
          data.description !== "" ||
          (data.sum.from !== null && data.sum.to !== null) ||
          (data.date.from !== null && data.date.to !== null)
        ) {
          getPaymentsList(this);
        }
      },
    },
  },
  mounted() {
    getPaymentsList(this);
  },
};
</script>

<style lang="scss" scoped>
.overlock {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10001;
  }
}
</style>

<!-- ^(?:(?:31(\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\.)(?:0?[1,3-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$ -->
